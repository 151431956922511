import { graphql, useStaticQuery } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query {
      events: allContentfulEventos(
        filter: { fotos: { elemMatch: { file: { contentType: { ne: "" } } } } }
        sort: { fields: [data], order: DESC }
      ) {
        nodes {
          rawDate: data
          title: nomeDoEvento
          id
          briefDescription: breveDescricaoDoEvento {
            description: breveDescricaoDoEvento
          }
          fullDescription: descricaooDoEvento {
            description: descricaooDoEvento
          }
          address: endereoDoEvento {
            address: endereoDoEvento
          }
          video: urlDoVideo
          photo: fotos {
            fluid {
              src
            }
          }
        }
      }
      news: allContentfulNoticias(sort: { fields: [data], order: DESC }) {
        nodes {
          id
          rawDate: data
          newsTitle: tituloDaNoticia
          briefDescription: breveDescricao
          url: link
        }
      }
      videos: allContentfulVideosDaPaginaDeEventosENoticias {
        nodes {
          id
          title: tituloDoVideo
          url: urlDoVideo
        }
      }
    }
  `)
