export const formatUpcommingEventsDate = (eventDate) => {
  const rawDate = new Date(eventDate)
  const formattedDate = rawDate
    .toLocaleDateString('pt-BR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
    .split(' de ')
    .join(' ')

  const formattedHour = rawDate
    .toLocaleTimeString('pt-BR', { hour12: false })
    .substr(0, 5)

  return `${formattedDate} | ${formattedHour}`
}

export const formatPastEventsDate = (eventDate) => {
  const rawDate = new Date(eventDate)
  const formattedDate = rawDate
    .toLocaleDateString('pt-BR', {
      month: 'long',
      year: 'numeric',
    })
    .split(' de ')
    .join(' - ')

  return formattedDate
}

export const isPastDate = (date) =>
  new Date(date).getTime() < new Date().getTime()
