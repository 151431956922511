import React from 'react'
import { ModalProvider } from 'styled-react-modal'
import { SwiperSlide } from 'swiper/react'

import Carousel from 'components/Carousel'
import Button from 'components/Button'

import {
  CustomModal,
  Overlay,
  CloseButton,
  Close,
  Body,
  EventType,
  Title,
  Description,
  BottomContainer,
  Amoeba1,
  Amoeba2,
  ButtonContainer,
  Image,
  CarouselContainer,
} from './style'

const EventModal = ({ isModalOpen, onRequestClose, data, isPreviewHome }) => {
  const renderCarousel = (photos) => (
    <CarouselContainer>
      <Carousel
        slidesPerView={1}
        spaceBetweenCards={10}
        nextButtonClass="events-image"
        prevButtonClass="events-image"
      >
        {photos.map((photo) => (
          <SwiperSlide key={photo.fluid?.src}>
            <Image src={photo.fluid?.src} />
          </SwiperSlide>
        ))}
      </Carousel>
    </CarouselContainer>
  )

  return (
    <ModalProvider backgroundComponent={Overlay}>
      <CustomModal
        isOpen={isModalOpen}
        onBackgroundClick={onRequestClose}
        onEscapeKeydown={onRequestClose}
      >
        <CloseButton onClick={onRequestClose}>
          <Close />
        </CloseButton>
        <Body>
          <EventType>
            <p>eventos&nbsp;</p>
            <p>|</p>
            <p>&nbsp;{data.formattedDate}</p>
          </EventType>
          <Title>{data.title}</Title>
          <Description>
            <p>{data.fullDescription?.description}</p>
            {isPreviewHome && data?.address?.address && (
              <>
                <p>Endereço:</p>
                <p>{data.address.address}</p>
              </>
            )}
          </Description>
          {((data?.video && data?.video !== '###') || data?.photo) && (
            <BottomContainer>
              <Amoeba1 />
              <Amoeba2 />
              {data.video && (
                <iframe
                  width="660"
                  height="409"
                  src={`https://www.youtube.com/embed/${data.video}`}
                  title="video-evento"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={data?.photo.length && { marginBottom: '150px' }}
                />
              )}
              {data?.photo?.length === 1 && (
                <Image src={data?.photo[0]?.fluid?.src} alt="" />
              )}
              {data?.photo?.length > 1 && renderCarousel(data?.photo)}
            </BottomContainer>
          )}
          <ButtonContainer>
            <Button onClick={onRequestClose}>Voltar</Button>
          </ButtonContainer>
        </Body>
      </CustomModal>
    </ModalProvider>
  )
}
export default EventModal
