import styled, { keyframes } from 'styled-components'
import Modal from 'styled-react-modal'
import Vector1 from 'assets/vectors/news-next-events-vector1.svg'
import Vector2 from 'assets/vectors/news-next-events-vector2.svg'

const show = keyframes`
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  `

export const CustomModal = Modal.styled`
  width: 1127px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 29px 40px 116px;
  margin-top: 40px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 90%;
      padding: 18px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      width: 100%;
    }
  `}
`

export const Overlay = styled.div`
  display: flex;
  position: absolute;
  width: 100vw;
  inset: 0px;
  top: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  animation: ${show} 0.4s ease forwards;
`

export const CloseButton = styled.button`
  position: relative;
  align-self: flex-end;
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
`

export const Close = styled.div`
  position: absolute;
  left: -3px;
  top: 0;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 24px;
    width: 3px;
    background-color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

export const Body = styled.div`
  display: flex;
  width: 700px;
  flex-direction: column;
  z-index: 10;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const EventType = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 23px;

  p {
    ${({ theme }) => theme.fonts.largeBold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.neutralMedium};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-bottom: 17px;
      p {
        ${theme.fonts.mediumTextBold};
      }
    }
  `}
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.tertiaryMedium};
  margin-bottom: 56px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading4};
      margin-bottom: 17px;
    }
  `}
`

export const Description = styled.div`
  max-width: 663px;
  margin-bottom: 60px;

  P {
    ${({ theme }) => theme.fonts.largeText}
    color: ${({ theme }) => theme.colors.neutralDarkest};
    margin-bottom: 30px;
    white-space: pre-line;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      margin-bottom: 30px;

      p {         
        ${theme.fonts.smallTextSemiBold};
      }
    }
  `}
`

export const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  iframe {
    position: relative;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      justify-content: center;
      align-items: center;

      iframe {
        width: 302px;
        height: 202px;
        margin-bottom: 30px !important;
      }
    }
  `}
`

export const Amoeba1 = styled(Vector1)`
  position: absolute;
  width: 230px;
  height: 267px;
  bottom: 300px;
  right: -150px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const Amoeba2 = styled(Vector2)`
  position: absolute;
  width: 250px;
  height: 250px;
  bottom: -75px;
  left: -155px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 72px;
`

export const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
`

export const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  width: 700px;
  height: 500px;
  transform: translateX(-16px);

  .swiper-slide {
    width: unset;
    display: flex;
    justify-content: center;
  }

  .swiper-button-disabled {
    background: ${({ theme }) => theme.colors.neutralRegular};
    &:hover {
      background: ${({ theme }) => theme.colors.neutralRegular};
    }
  }

  .swiper-button-next-events-image {
    position: absolute;
    top: 206px;
    right: -82px;
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.smallTablet}) {
        top: unset;
        bottom: -100px;
        right: 300px;
      }
    `}
  }

  .swiper-button-prev-events-image {
    position: absolute;
    top: 206px;
    left: -82px;

    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.smallTablet}) {
        top: unset;
        bottom: -100px;
        left: 300px;
      }
    `}
  }
`
